<template>
  <main>
    <main-layout itemMenuActive="12" tituloMenu="Transacciones por usuario">
      <div class="container">
        <br />
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <form v-on:submit.prevent="getTransactions">
                  <h2>Reporte de transacciones por usuario</h2>
                  <div style="display: flex">
                    <div class="column is-3">
                      <b-field label="Fecha:" :message="validateForm.fecha.message" :type="validateForm.fecha.type">
                        <b-datepicker
                            placeholder="Seleccione fecha..."
                            v-model="model.fecha"
                            range>
                        </b-datepicker>
                      </b-field>
                    </div>
                    <div class="column is-5">
                      <b-field label="Cédula:" :message="validateForm.cedula.message" :type="validateForm.cedula.type">
                        <b-numberinput placeholder="Ingrese número de cédula..."
                                       v-model="model.cedula" :controls="false"
                                       type="search"
                                       icon="magnify">
                        </b-numberinput>
                        <p class="control">
                          <b-button type="is-primary" label="Consultar" native-type="submit"/>
                        </p>
                      </b-field>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <section v-if="data.length > 0">
                  <div style="display: flex;justify-content: flex-end">
                    <b-field class="column is-6" label="Búsqueda por guid o por estado">
                      <b-input v-model="searchQuery" placeholder="Escriba guid o estado..." autofocus/>
                    </b-field>
                    <br>
                  </div>
                  <b-table
                      :data="filter"
                      striped
                      paginated
                      backend-pagination
                      :total="data.length"
                      :per-page="10"
                      backend-sorting
                      :opened-detailed="[1]"
                      detailed
                      detail-key="operationGuid"
                      :detail-transition="'fade'"
                      :show-detail-icon="true">

                    <b-table-column field="num" label="Nro." v-slot="props">
                      {{ props.row.num }}
                    </b-table-column>
                    <b-table-column field="operationGuid" label="GUID" v-slot="props">
                      <b-tag>{{ props.row.operationGuid }}</b-tag>
                    </b-table-column>
                    <b-table-column field="createdAt" label="Fecha" centered v-slot="props">
                      {{ dateFormating(props.row.createdAt) }}
                    </b-table-column>
                    <b-table-column field="names" label="Nombre y apellido" centered v-slot="props">
                      {{ `${props.row.names} ${props.row.lastNames}` }}
                    </b-table-column>
                    <b-table-column field="status" label="Estado" v-slot="props">
                      <b-tag :type="getTag(props.row.scoreConfigured, props.row.scoreTotal,props.row.status)">{{getStatus(props.row.status)}}</b-tag>
                    </b-table-column>
                    <template #bottom-left>
                      <b>Total registros encontrados</b>: {{ filter.length }}
                    </template>
                    <template #detail="props">
                      <article class="media">
                        <div class="media-content">
                          <div class="content">
                            <h3>Información adicional</h3>
                            <hr>
                            <p>
                              <strong>Nombre completo: {{ props.row.names}} {{ props.row.lastNames }}</strong><br>
                              <b-tag>GUID: {{ props.row.operationGuid}}</b-tag><br>
                              <small>Fecha: {{ dateFormating(props.row.createdAt) }}</small><br>
                              <small>Documento de identidad: {{ props.row.documentId}}</small><br>
                              <small>Nombres: {{ props.row.names}}</small><br>
                              <small>Apellidos: {{ props.row.lastNames}}</small><br>
                              <small>Score configurado: {{ (props.row.scoreConfigured * 100).toFixed(2)}}%</small><br>
                              <small>Score total: {{ (props.row.scoreTotal * 100).toFixed(2)}}%</small><br>
                              <b-tag :type="getTag(props.row.scoreConfigured, props.row.scoreTotal,props.row.status)">
                                Estado: {{getStatus(props.row.status)}}
                              </b-tag>
                            </p>
                          </div>
                        </div>
                      </article>
                    </template>
                  </b-table>
                </section>
                <div v-else>
                  <b-message type="is-info"> Sin datos para procesar. </b-message>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
      <br>
      <br>
      <br>
    </main-layout>
  </main>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapActions} from "vuex";
export default {
  name: "PorUsuario",
  components: {MainLayout},
  data() {
    return {
      data: [],
      searchQuery: '',
      isLoading: false,
      model: {
        fecha: [],
        cedula: null
      },
      validateForm: {
        fecha: {
          message: '',
          type: ''
        },
        cedula: {
          message: '',
          type: ''
        }
      }
    }
  },
  computed: {
    filter() {
      let data = this.data;
      if (this.searchQuery != ''){
        let newData = data.filter(item => { if (item.operationGuid.includes(this.searchQuery) || this.getStatus(item.status).includes(this.searchQuery)) return item });
        newData.forEach((item, index) => {item.num = index + 1;} )
        return newData;
      }
      data.forEach((item, index) => {item.num = index + 1;} );
      return data
    }
  },
  methods:{
    ...mapActions(['_axios']),
    dateFormating(date){
      const option = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute:'numeric', second:'numeric', hour12:true};
      let dateFormated = new Date(date).toLocaleString('es-CO', option);
      return dateFormated[0].toUpperCase() + dateFormated.slice(1);
    },
    getTransactions(){
      if (!this.validate())
        return;
      this.isLoading = true;
      const options = {
        method: "post",
        uri: "boards/transacionesporusuario",
        data: {
          Cedula: `${this.model.cedula}`,
          FechaConsulta: this.model.fecha,
        },
      };
      this._axios(options).then(res => {
        this.data = res.data.info;
        this.model = {
          fecha: [],
          cedula: null
        }
      }).finally(() => {
        this.cancelarLoading();
      })
    },
    getStatus(status){
      let map = new Map();
      map.set('Pending', 'Pendiente')
      map.set('Canceled', 'Cancelado')
      map.set('Restore', 'Restaurado')
      map.set('Rejected', 'Rechazado')
      map.set('Ended', 'Finalizado')
      map.set('Risky', 'Arriesgada')
      map.set('Replaced', 'Reemplazada')
      map.set('Accepted by operator', 'Aceptada por el operador')
      map.set('Rejected by operator', 'Rechazada por el operador')
      map.set('User anonymized', 'Usuario anónimo')
      map.set('Risky by spoofing', 'Riesgo de suplantación')
      return map.get(status)
    },
    getTag(scoreConfigured, scoreTotal, status){
      if (scoreConfigured > scoreTotal)return 'is-danger';
      if (status == "Pending")return 'is-warning';
      else if (status == "Canceled")return 'is-warning';
      else if (status == "Restore")return 'is-info';
      else if (status == "Rejected")return 'is-danger';
      else if (status == "Risky")return 'is-danger';
      else if (status == "Replaced")return 'is-info';
      else if (status == "Risky by spoofing")return 'is-danger';
      else if (status == "User anonymized")return 'is-danger';
      else if (status == "Rejected by operator")return 'is-danger';
      else if (status == "Accepted by operator")return 'is-info';
      return 'is-success';
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    validate(){
      if (this.model.fecha.length === 0){
        this.validateForm.fecha.message = 'Debe ingresar un rango de fechas válido';
        this.validateForm.fecha.type = 'is-danger';
        return false;
      }
      if (this.model.cedula === null){
        this.validateForm.cedula.message = 'Debe ingresar una cédula válida';
        this.validateForm.cedula.type = 'is-danger';
        return false;
      }
      this.validateForm.fecha.message = '';
      this.validateForm.fecha.type = '';

      this.validateForm.cedula.message = '';
      this.validateForm.cedula.type = '';
      return true;
    }
  }
}
</script>